import Vue from 'vue'
// import VueJsonp from 'jsonp'
import App from './App.vue'
import router from './router'
import store from './store'


// 引入的ui框架vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
// import VueJsonp from 'jsonp'

// Vue.use(VueJsonp);

// 封装整个项目的数据请求处理文件
import request from "./utils/request.js";
Vue.prototype.$request = request;
// 更具更字体的改变进行页面的大小改变
import flexible from "./utils/flexible.js"
flexible();
// localstorage的存取封装
import storage from "./utils/storage.js";
Vue.prototype.$storage = storage;
//获取路由带的参数
import urlKey from "./utils/urlKye.js"
Vue.prototype.$urlKey = urlKey;
//查看当前的打开的方式
import model from "./utils/model.js"
Vue.prototype.$model = model;

// import setTimes from "./utils/setTime.js"
// Vue.prototype.$setTimes = setTimes;

Vue.prototype.$sleep = time => {
	return new Promise((resolve, reject) => {
		window.setTimeout(() => {
			resolve('ok')
		}, time)
	})
}



Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
