<template>
  <div id="app">
    <div id="nav">
      <router-view />
    </div>
  </div>
</template>

<script>
window.addEventListener("storage", function (e) {
  console.log("e");
  console.log(e);
  if (e.key == "code") {
    var selectInfoObj = JSON.parse(e.newValue);
    console.log(selectInfoObj);
  }
});

// import HomeIndex from "./components/Home/HomeIndex.vue";
window.onbeforeunload = function () {
  if (
    (event.clientX > document.body.clientWidth && event.clientY < 0) ||
    event.altKey
  ) {
    var storage = window.localStorage; //方法
    storage.clear();
  }
};

export default {
  name: "App",
  data() {
    return {};
  },

  methods: {},
  beforeCreate() {},
  created() {},
  components: {
    // HomeIndex,
  },
  computed: {},
};
</script>

<style>
#app {
  /* font-size: 1rem; */
}
* {
  margin: 0;
  padding: 0;
}
</style>
