import axios from 'axios';
let baseURL = 'https://equitycouponapi.yinheyun.com.cn/api'//正式环境
// let baseURL = '/apis' //测试环境
// http://xiaodianadmin.yinheyun.com.cn/api.php/Index
// http://192.168.124/api.php/Index 
function requestapi(options) {
	// 1,创建一个axios实例
	return new Promise((resolve, reject) => {
		const instance = axios.create({
			method: 'POST',
			baseURL,
			timeout: 5000,
			headers: {
				"Content-type": "application/x-www-form-urlencoded"
			},
			// dataType: 'jsonp', 
		});
		// 配置请求拦截
		// 请求拦截配置
		instance.interceptors.request.use(config => {
			// console.log("请求之前的拦截");
			// 会对请求最通用配置
			return config;
		}, err => {
			// console.log("请求拦截失败",err);
			return err;
		})
		// 响应拦截配置
		instance.interceptors.response.use(response => {
			// console.log("响应之前的拦截");
			return response.data;
		}, err => {
			// console.log("响应拦截失败",err);
			if (err && err.response) {
				switch (err.response.status) {
					case 400:
						err.message = "请求错误";
						break;
					case 500:
						err.message = "服务器错误"
						break;
				}
			}
			return err;
		})

		instance(options).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err);
		})
	})
}




// function threeRe(options) {
// 	// 1,创建一个axios实例
// 	return new Promise((resolve, reject) => {
// 		const instance = axios.create({
// 			baseURL:'http://api.map.baidu.com',
// 			timeout: 5000,
// 			headers: {
// 				"Content-type": "Access-Control-Allow-Origin"
// 			},
// 			// dataType: 'jsonp', 
// 		});
// 		// 配置请求拦截
// 		// 请求拦截配置
// 		instance.interceptors.request.use(config => {
// 			// console.log("请求之前的拦截");
// 			// 会对请求最通用配置
// 			return config;
// 		}, err => {
// 			// console.log("请求拦截失败",err);
// 			return err;
// 		})
// 		// 响应拦截配置
// 		instance.interceptors.response.use(response => {
// 			// console.log("响应之前的拦截");
// 			return response.data;
// 		}, err => {
// 			// console.log("响应拦截失败",err);
// 			if (err && err.response) {
// 				switch (err.response.status) {
// 					case 400:
// 						err.message = "请求错误";
// 						break;
// 					case 500:
// 						err.message = "服务器错误"
// 						break;
// 				}
// 			}
// 			return err;
// 		})

// 		instance(options).then(res => {
// 			resolve(res);
// 		}).catch(err => {
// 			reject(err);
// 		})
// 	})
// }
export {
	requestapi
	// threeRe
}
