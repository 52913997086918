import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

	{
		path: '/',
		name: 'index',
		component: () => import('../views/Index.vue'),
	},
{
		path: '/show',
		name: 'show',
		component: () => import('../views/show.vue'),
	},
	{
			path: '/detail',
			name: 'detail',
			component: () => import('../views/detail.vue'),
		},
		{
				path: '/introduce',
				name: 'introduce',
				component: () => import('../views/introduce.vue'),
			},
		

]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
