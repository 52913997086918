import { request, threeRe, jsonp } from "./network.js";
import { requestapi } from "./networkapi.js";



function getList(obj) { //报名
	return request({
		url: "/getList",
		params: {
			store_id: obj.store_id,
		}
	})
}
function getBanner(obj) { //报名
	return request({
		url: "/getBanner",
		params: {
			store_id: obj.store_id,
		}
	})
}
function sendPhoneCode(obj) { //报名
	return request({
		url: "/sendPhoneCode",
		headers: {
			"Content-type": "application/json"
		},
		data: {
			phone: obj.phone,
		}
	})
}
function checkPhone(obj) { //报名
	return request({
		url: "/checkPhone",
		params: {
			phone: obj.phone,
			code: obj.code,
			stock_id: obj.stock_id,
		}
	})
}
function getMoreInfo(obj) { //报名
	return request({
		url: "/getMoreInfo",
		params: {
			id: obj.id,
		}
	})
}
function getInfo(obj) { //报名
	return request({
		url: "/getInfo",
		params: {
			id: obj.id,
		}
	})
}

function getOpenId(obj) { //登录
	return requestapi({
		headers: {
			"Content-type": "application/json"
		},
		url: "/getOpenId",
		data: {
			// storeid: 1,
			code: obj.code,
		}
	})
}

function sendCoupon(obj) { //登录
	return requestapi({
		headers: {
			"Content-type": "application/json"
		},
		url: "/sendCoupon",
		data: {
			// storeid: 1,
			stock_id: obj.stock_id,
			openid: obj.openid,
			phone: obj.phone,
		}
	})
}



function imgUrl() { //图片上传路径
	return 'http://equitycoupon.yinheyun.com.cn/uploads/' //线上
}


//对字符串进行加密   
function compileStr(code) {
	var c = String.fromCharCode(code.charCodeAt(0) + code.length);
	for (var i = 1; i < code.length; i++) {
		c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
	}
	return escape(c);
}
//字符串进行解密   
function uncompileStr(code) {
	code = unescape(code);
	var c = String.fromCharCode(code.charCodeAt(0) - code.length);
	for (var i = 1; i < code.length; i++) {
		c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
	}
	return c;
}
//获取url参数
function getUrl(name) {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) return decodeURI(r[2]);
	return null;
}
//全局定时任务
function orderTime(obj) {
	const timer = setInterval(function get() {
		clearInterval(timer);
		alert('已经十五分钟了');
	}, obj);
}





export default {
	getBanner,
	checkPhone,
	sendPhoneCode,
	getMoreInfo,
	getInfo,
	getList,
	sendCoupon,
	getOpenId,
	getUrl,
	compileStr,
	uncompileStr,
	imgUrl,
}
